.heading-Toolbar-Style {
  --background: #ffffff;
  --border-color: var(--Brand-Colour-Primary);
  --border-width: 8px 0;
}

.headingRow {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 70px;
 
}

.headingRow img {
  height: 32px;
}

.login-Card {
  display: flex;
  --background: #BFBFBF;
  border-radius: 15px;
  color: #000000;
  z-index: 1001000;
  position: relative;
  height: 40px;
  bottom: 65px;
  animation-name: loginTest;
  animation-iteration-count: 1;
  animation-duration: 1s;
  animation-fill-mode: forwards;
}

.login-Icon {
  width: 40px;
  height: 40px;
}

.login-Card:hover {
  animation-name: loginDropDown;
  animation-iteration-count: 1;
  animation-duration: 1s;
  animation-fill-mode: forwards;
  background: #101010;
  z-index: 1001;
  position: relative;
}



@keyframes loginDropDown {
  0% {
    height: 40px; 
    bottom: 65px;
    color: rgb(255, 255, 255); 
    opacity: 90%;
  }
  25% {
    height: 88px;
    bottom: 65px;
    opacity: 80%;
  }
  100% {
    opacity: 70%;
    bottom: 65px;
    height: 88px;
    color: rgb(255, 255, 255); 
  }
}

@keyframes loginTest {
  0% {
    opacity: 70%;
    bottom: 65px;
    height: 88px;
    color: rgb(255, 255, 255); 
  }
  25% {
    height: 40px;
    bottom: 65px;
    opacity: 80%;
    color: rgb(0, 0, 0);
  }
  100% {
    opacity: 100%;
    height: 40px;
    bottom: 65px;
   
  }
}

.logout-Button {
  width: 90%;
  --background: #BF4040;
  color: rgb(255, 255, 255);
  position: relative;
}